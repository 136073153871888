<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    name: 'App',
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
    font-size: 14px;
    text-align: justify;
}

.container {
    max-width: 450px;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow-y: scroll;
    box-sizing: border-box;
}

*::-webkit-scrollbar {
    display: none;
}
</style>
