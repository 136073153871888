import Vue from 'vue'
import VueRouter from "vue-router"
Vue.use(VueRouter)

const routes = [
    {
        path: "/detail",
        component: () => import('@/pages/Detail')
    }, 
    {
        path: "/",
        component: () => import('@/pages/Index')
    }, {
        path: "/all",
        component: () => import('@/pages/All')
    }, {
        path: "/contact",
        component: () => import('@/pages/Contact')
    }, {
        path: "/infor",
        component: () => import('@/pages/Infor')
    }, {
        path: "/check",
        component: () => import('@/pages/Check')
    }, {
        path: "/home",
        component: () => import('@/pages/Home')
    }, {
        path: "/success",
        component: () => import('@/pages/Success')
    }, {
        path: "/start",
        component: () => import('@/pages/Test')
    },
]
const router = new VueRouter({
    mode: 'history',
    routes
})

export default router